<template>
  <div>
    <div
      class="row m-2"
      style="border-radius: 10px; border: 1px solid black; font-size: 15px"
    >
      <div class="w-75">
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="w-100 d-flex text-center justify-content-center align-items-center">
            <b class="mr-1">{{ $t("order_form.stop") }}</b>
            <v-checkbox
              v-model="data.status"
              class="m-0 p-0"
              size="5"
              hide-details
              :true-value="0"
              :false-value="1"
            >
            </v-checkbox>
            <b class="mr-1">{{ $t("order_form.okey") }}</b>
            <v-checkbox
              v-model="data.status"
              class="m-0 p-0"
              size="5"
              hide-details
              :true-value="1"
              :false-value="0"
            >
            </v-checkbox>
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13 w-30">{{ lang["control_type"] }} :</div>
          <div class="w-60">
            <span v-if="data['form_type'] == 1">{{ lang["inception_report"] }}</span>
            <span v-if="data['form_type'] == 2">{{
              lang["interim_control_report"]
            }}</span>
            <span v-if="data['form_type'] == 3">{{ lang["final_report"] }}</span>
          </div>
        </div>

        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["supplier_company"] }} :</div>

          <div>
            {{ data.supplier_company.name }}
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["model"] }} :</div>

          <div>
            {{ data.order.model }}
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["company"] }} :</div>
          <div>
            {{ data.order.company.name }}
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["order_number"] }} :</div>
          <div>
            {{ data.order.order_number }}
          </div>
        </div>

        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["po_number"] }} :</div>
          <div>
            {{ data.order.po_number }}
          </div>
        </div>

        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["operation_type"] }} :</div>
          <div>
            {{ data.operation_type }}
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["checked_qty"] }} :</div>
          <div>
            {{ data["checked_qty"] }}
          </div>
        </div>

        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["audit_date"] }} :</div>
          <div>
            {{ moment(data["created_at"]).format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="d-flex flex-row text-left order-border-bottom py-2 px-2">
          <div class="fs-13">{{ lang["audit_adress"] }} :</div>
          <div>
            {{ data.supplier_company.address }}
          </div>
        </div>

        <div class="d-flex flex-row text-left py-2 px-2">
          <div class="fs-13">{{ lang["form_creator"] }} :</div>
          <div>
            {{ data.user.name }}
          </div>
        </div>
        <div class="d-flex flex-row text-left order-border-top py-2 px-2" v-if="data.stop_options && data.stop_options.length">
          <div class="fs-13">{{ lang["stop_reason"] }} :</div>
          <div>
            {{ formatedStopOptions }}
          </div>
        </div>
      </div>
      <div
        class="w-25 order-border-left d-flex justify-content-center align-items-center"
      >
        <img class="object-cover" :src="data.order.image_link_large" />
      </div>
    </div>
    <div
      class="row m-2 mt-5"
      style="border-radius: 10px; border: 1px solid black; font-size: 15px"
    >
      <div class="col-3 text-center order-border-right order-border-bottom">
        <span class="fs-13">
          {{ lang["measurement_location"] }}
        </span>
      </div>
      <div class="w-75 text-center order-border-bottom">
        <span class="fs-13">
          {{ lang["sizes"] }}
        </span>
      </div>
      <div v-if="data.measuring_location.length > 0" style="display: contents">
        <template v-for="(measuring_location, measuringIndex) in data.measuring_location">
          <div class="w-100">
            <div
              class="mx-0 text-center m-0 p-0"
              :class="{ 'navy-blue-border-top-1px': measuringIndex > 0 }"
            >
              <div class="d-flex flex-row" v-if="measuring_location.sizes.length">
                <div
                  class="col-3 d-flex justify-content-center align-items-end pb-3 text-lg font-weight-bold font-size-12px"
                  :class="{
                    'navy-blue-border-right-1px':
                      index !== measuring_location.sizes.length - 1,
                  }"
                >
                  <span>
                    {{ measuring_location.measuring_location }}
                  </span>
                </div>
                <div
                  class="flex-fill m-0 p-0"
                  :class="{
                    'navy-blue-border-right-1px':
                      index !== measuring_location.sizes.length - 1,
                  }"
                  v-for="(size, index) in measuring_location.sizes"
                >
                  <div class="px-1 navy-blue-border-bottom-1px fs-13">
                    {{ size.name }}
                  </div>
                  <div class="d-inline-block px-1">
                    <span class="px-1 navy-blue-border-right-1px">{{ size.note_1 }}</span>
                    <span class="px-1 navy-blue-border-right-1px">{{ size.note_2 }}</span>
                    <span class="px-1">{{ size.note_3 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      class="row m-2 mt-5"
      style="border-radius: 10px; border: 1px solid black; font-size: 15px"
    >
      <div class="col-12 order-border-bottom text-center">
        <span class="fs-13">
          {{ lang["measures_to_be_taken"] }}
        </span>
      </div>
      <div class="col-12">
        {{ data.measures }}
      </div>
    </div>
    <div
      class="row m-2 mt-5"
      style="border-radius: 10px; border: 1px solid black; font-size: 15px"
    >
      <div class="col-12 order-border-bottom text-center">
        <span class="fs-13">
          {{ lang["released_errors"] }}
        </span>
      </div>
      <div class="col-12">
        {{ data.errors }}
      </div>
    </div>
    <div
      class="row m-2 mt-5"
      style="border-radius: 10px; border: 1px solid black; font-size: 15px"
    >
      <div class="col-12 order-border-bottom text-center">
        <span class="fs-13">
          {{ lang["images"] }}
        </span>
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-3 float-left m-5 text-center p-3"
          style="display: contents"
          v-for="file in files"
        >
          <img class="object-cover-2" :src="file.file_small_link" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SizesAmount from "@/view/pages/order/pdf/order-pdf-layout/SizesAmount";
import SubTotal from "@/view/pages/order/pdf/order-pdf-layout/SubTotal";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
export default {
  name: "midForm",
  props: {
    data: {
      required: true,
    },
    lang: {
      required: true,
    },
    files: {
      required: true,
    },
    formStopOptions: {
      required: true,
    },
  },
  components: {
    SizesAmount,
    SubTotal,
  },
  computed: {
    moment: () => moment,
    formatedStopOptions() {
      let data = [];
      this.data.stop_options.forEach((option) => {
        console.log(this.formStopOptions);
        Object.entries(this.formStopOptions).forEach(([key, optionValue]) => {
          if (option == key) {
            data.push(optionValue);
          }
        });
      });
      return data.join();
    },
  },
  methods: {
    downloadPdf() {
      this.$emit("downloadPdf");
    },
    closeModal() {
      this.$emit("downloadPdf");
    },
  },
};
</script>

<style>
.logo_padding {
  padding-top: 50px !important;
}
.QK_text {
  padding-top: -10px !important;
  font-size: 5px;
}
.order-border {
  border: 1px solid black;
}
.order-border-bottom {
  border-bottom: 1px solid black;
}
.order-border-top {
  border-top: 1px solid black;
}
.order-border-left {
  border-left: 1px solid black;
}
.order-border-right {
  border-right: 1px solid black;
}
.border-none {
  border: none;
}
.border-right-none {
  border-right: none;
}
.border-left-none {
  border-left: none;
}
.border-top-none {
  border-top: none;
}
.border-bottom-none {
  border-bottom: none;
}
.-mt-80px {
  margin-top: -80px !important;
}
.-mt-100px {
  margin-top: -100px !important;
}
.-mt-50px {
  margin-top: -50px !important;
}
.-mt-30px {
  margin-top: -30px !important;
}
.object-cover {
  object-fit: cover;
  width: 250px;
  height: auto;
}
.object-cover-2 {
  object-fit: cover;
  width: 300px;
  height: auto;
  margin: 10px;
}
.font-size-7px {
  font-size: 7px;
}
.font-size-12px {
  font-size: 12px !important;
}
.font-size-11px {
  font-size: 11px !important;
}

.font-size-10px {
  font-size: 10px !important;
}

.font-size-9px {
  font-size: 9px !important;
}
.font-size-8px {
  font-size: 8px !important;
}
.mt-5px {
  margin-top: 5px;
}
.border-radius-5px {
  border-radius: 5px;
}
.border-radius-10px {
  border-radius: 10px;
}
.border-radius-15px {
  border-radius: 15px;
}
.border-radius-20px {
  border-radius: 20px;
}
.border-radius-25px {
  border-radius: 25px;
}
.border-radius-30px {
  border-radius: 30px;
}
.mt-10px {
  margin-top: 10px;
}
.mb-10px {
  margin-bottom: 10px;
}
.w-100 {
  width: 99.9% !important;
}
.w-50 {
  width: 49.9% !important;
}
.w-25 {
  width: 24.85% !important;
}
.w-20 {
  width: 19.85% !important;
}
.w-33 {
  width: 33.2% !important;
}
.w-16 {
  width: 16.52% !important;
}
.w-17 {
  width: 17% !important;
}
.w-14 {
  width: 14.13% !important;
}
.w-11 {
  width: 10.9% !important;
}
.w-10 {
  width: 9.85% !important;
}
.fs-13 {
  font-size: 15px !important;
  font-weight: bold;
}
.fsl-13 {
  font-size: 15px !important;
}
.pl-4 {
  padding-left: 1.25rem;
}
</style>
