<template>
  <tr>
    <td style="width: 70px">{{ orderSizeAndColors["color_number"] }}</td>
    <td style="width: 70px">{{ orderSizeAndColors["color_name"] }}</td>
    <template v-for="name in  data_size_names">
        <td>{{data_size_amount[name]}}</td>
    </template>

    <td>{{  numberFormat(sum_data_size_amount) }}</td>
    <template v-for="orderFabric in orderFabrics">
      <td>{{ numberFormat(orderFabric["pastal"] * sum_data_size_amount) }}</td>
    </template>
    <td>{{numberFormat(order_fabrics_total)}}</td>
  </tr>
</template>
<script>
export default {
  name: "SizesAmount",
  props: {
    orderSizeAndColors: {
      required: true,
    },
    sizes: {
      required: true,
    },
    orderFabrics: {
      required: true,
    },
    dataSizeNamesHead: {
      required: true,
    },
  },
  computed: {
    data_size_names() {
      let data = [];
      this.sizes.forEach((size) => {
        if (this.dataSizeNamesHead.includes(size)) {
          data.push(size);
        }
      });
      return data;
    },
    order_fabrics_total(){
        let total=0;
        this.orderFabrics.forEach((fabric)=>{
            total +=fabric['pastal'] * this.sum_data_size_amount
        })
        return total;
    },
    sum_data_size_amount() {
      let sum = 0;
      this.sizes.forEach((size) => {
        if (this.data_size_names.includes(size)) {
          sum += +this.data_size_amount[size];
        }
      });
      return sum;
    },
    orderFabricTotal() {
      this.orderFabrics.forEach((orderFabric) => {});
    },
    data_size_amount() {
      let data = {};
      this.sizes.forEach((size) => {
        if (this.dataSizeNamesHead.includes(size)) {
          data[size] = 0;
        }
      });
      let body_sizes = JSON.parse(this.orderSizeAndColors["body_sizes"]);

      Object.entries(body_sizes).forEach((body_name) => {
        data[body_name[1]["name"]] = body_name[1]["amount"];
      });

      return data;
    },
  },
  methods: {
    numberFormat(number) {
      return Number(number).toLocaleString('tr-TR', { minimumFractionDigits: 2 });
    },
  },
};
</script>
