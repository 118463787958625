<template>
  <div class="px-1">
    <b-modal
      id="display-mid-form-pdf"
      ref="display-mid-form-pdf"
      hide-header
      hide-header-close
      scrollable
      size="xl"
    >
      <div id="reportPrint" class="container">
        <order-mid-pdf
          :form-stop-options="formStopOptions"
          :lang="orderReportData.lang"
          :data="orderReportData.data"
          :files="orderReportData.files"
        >
        </order-mid-pdf>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal('display-mid-form-pdf')"
          >
            {{ $t("general.close") }}
          </button>
          <button
            class="btn btn-sm btn-outline-info p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="downloadPdf(orderReportData.data.pdf_link)"
          >
            {{ $t("order.download_as_pdf") }}
          </button>

          <button
            class="btn btn-sm btn-outline-info p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            v-print="reportPrintObject"
          >
            {{ $t("general.print") }}
          </button>
        </div>
      </template>
    </b-modal>

    <template v-for="(currentDetail, index) in model">
      <h5 class="m-2 text-center">
        <button
          :class="{ selected: currentDetail.form_type != 1 }"
          @click="currentDetail.form_type = 1"
        >
          {{ $t("order_form.inception_report") }}
        </button>

        /
        <button
          :class="{ selected: currentDetail.form_type != 2 }"
          @click="currentDetail.form_type = 2"
        >
          {{ $t("order_form.interim_control_report") }}
        </button>
        /
        <button
          :class="{ selected: currentDetail.form_type != 3 }"
          @click="currentDetail.form_type = 3"
        >
          {{ $t("order_form.final_report") }}
        </button>
      </h5>
      <template>
        <div class="row mid-control-master border-15px">
          <div
            class="mt-2 d-flex col-12 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <b class="mr-1">{{ $t("order_form.stop") }}</b>
            <v-checkbox
              @change="selectStatus(index)"
              v-model="currentDetail.status"
              class="m-0 p-0"
              size="5"
              hide-details
              :true-value="0"
              :false-value="1"
            >
            </v-checkbox>
            <b class="mr-1">{{ $t("order_form.okey") }}</b>
            <v-checkbox
              v-model="currentDetail.status"
              @change="selectStatus(index)"
              class="m-0 p-0"
              size="5"
              hide-details
              :true-value="1"
              :false-value="0"
            >
            </v-checkbox>
          </div>

          <div
            class="mt-2 d-flex col-12 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
            v-if="currentDetail.status == 0"
          >
            <custom-multi-select
              :item-per-row="1"
              :model.sync="currentDetail.stop_options"
              :not-list="false"
              :options="formStopOptions"
              :placeholder="$t('general.please_select_option')"
              :required="false"
              :title="null"
              name="sourcingTypeOptions"
              class="pt-2"
              @save="changeSizes($event, index)"
            ></custom-multi-select>
          </div>
          <div
            class="mt-2 d-flex col-12 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <date-picker-input
              class="font-weight-bold d-flex justify-content-center"
              :model.sync="currentDetail.date"
              :required="false"
              :title="$t('general.date') + ':'"
            ></date-picker-input>
          </div>
          <div
            style="width: 100%"
            class="py-2 text-center col-12 navy-blue-border-bottom-1px"
          >
            <b>{{ $t("order_form.order_number") }}:</b> <br />{{ order.order_number }}
          </div>
          <div
            class="py-2 text-center col-12 justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <b>{{ $t("order_form.supplier_company") }}:</b>

            <custom-multi-select
              :item-per-row="1"
              :model.sync="currentDetail.supplier_company_id"
              :not-list="true"
              :max="1"
              :options="supplierCompanies"
              :placeholder="$t('general.please_select_option')"
              :required="false"
              :title="null"
              :is-inline="true"
              name="sourcingTypeOptions"
              class="pt-2 justify-content-center align-items-center"
              @save="changeSizes($event, index)"
            ></custom-multi-select>
          </div>

          <div
            class="mt-2 col-12 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <b>{{ $t("order_form.model_no") }}:</b>
            <p>{{ order.model }}</p>
          </div>
          <div
            class="my-2 col-12 pb-3 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <div class="row justify-content-center">
              <div class="col-12">
                <b class="pr-1">{{ $t("order_form.operation_type") }}: </b>
              </div>
              <div class="col-12">
                <input
                  v-model="currentDetail.operation_type"
                  class="form-control form-control-sm"
                  style="max-width: 100%"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div
            class="my-2 col-12 pb-3 text-center justify-content-center align-items-center navy-blue-border-bottom-1px"
          >
            <b class="pr-1">{{ $t("order_form.checked_qty") }}: </b>
            <input
              v-model="currentDetail.checked_qty"
              class="form-control form-control-sm"
              type="text"
            />
          </div>

          <div class="col-12 pb-3 d-flex navy-blue-border-bottom-1px">
            <div class="p-2 d-flex justify-content-center align-items-center">
              <b>{{ $t("order_form.sizes") }}:</b>
              <custom-multi-select
                :item-per-row="1"
                :model.sync="currentDetail.sizes"
                :not-list="false"
                :options="bodySizeOptions"
                :placeholder="$t('general.please_select_option')"
                :required="false"
                :title="null"
                name="sourcingTypeOptions"
                class="pt-2"
                @save="changeSizes($event, index)"
              ></custom-multi-select>
            </div>
            <div class="flex-1 p2 d-flex justify-content-center align-items-center">
              <span
                class="d-flex justify-content-center items-center cursor-pointer font-size-sm"
                @click="addNewSizeLine(index)"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.add_document', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75 ',
                    })
                  "
                >
                </span>
              </span>
            </div>
          </div>

          <div class="container-fluid" v-if="currentDetail.measuring_location.length > 0">
            <template
              v-for="(
                measuring_location, measuringIndex
              ) in currentDetail.measuring_location"
            >
              <div
                class="row pt-3 pb-5 mt-3 py-3 navy-blue-border-bottom-1px"
                :class="{ 'navy-black-border-top-1px': measuringIndex > 0 }"
              >
                <div
                  class="col-12 justify-content-center align-items-end pb-3 text-lg font-weight-bold measure-lines"
                >
                  <p>{{ measuringIndex + 1 }} . Satır</p>
                  <input
                    v-model="measuring_location.measuring_location"
                    required
                    class="form-control form-control-sm min-width-50"
                    type="text"
                  />
                  
                </div>
              </div>
              <div
                class="mx-0 d-flex justify-content-center align-items-center text-center m-0 p-0"
              >
                <div class="m-0 p-0" v-if="measuring_location.sizes.length">
                  <template v-for="(size, index) in measuring_location.sizes">
                    <div
                      class="row d-flex justify-content-center"
                      :class="{ 'navy-blue-border-top-1px mt-3': index > 0 }"
                    >
                      <div class="col-5 navy-blue-border-bottom-1px mb-1">
                        {{ size.name }}
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                v-model="size.note_1"
                                class="form-control form-control-sm"
                                style="width: 60px"
                                type="text"
                              />
                            </td>
                            <td>
                              <input
                                v-model="size.note_2"
                                class="form-control form-control-sm"
                                style="width: 60px"
                                type="text"
                              />
                            </td>
                            <td>
                              <input
                                v-model="size.note_3"
                                class="form-control form-control-sm"
                                style="width: 60px"
                                type="text"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>

                  <div class="row mt-2 d-flex align-content-center align-items-center justify-space-between mid-control-button-area">
                    <span
                    @click="deleteMeasuringLocation(index, measuringIndex)"
                    v-html="
                      getIconByKey('icons.sample.delete_red', {
                        class:
                          'm-0 p-0 object-cover d-inline-block opacity-75 mr-2 order-delete-button',
                      })
                    "
                  >
                  </span>
                  <span
                    class="d-flex justify-content-center items-center cursor-pointer font-size-sm"
                    @click="addNewSizeLine(index)"
                  >
                    <span
                      v-html="
                        getIconByKey('icons.waybill.add_document', {
                          class:
                            'object-cover d-inline-block opacity-75 order-plus-button ',
                        })
                      "
                    >
                    </span>
                  </span>
                  </div>

                </div>
              </div>
            </template>
          </div>
          <div class="text-center py-5 w-100 navy-blue-border-bottom-1px" v-else>
            <b>{{ $t("order_form.please_select_size") }}</b>
          </div>
          <div
            class="col-12 text-center p-2 justify-content-center navy-blue-border-bottom-1px"
          >
            <b>{{ $t("order_form.released_errors") }}</b>
            <textarea
              class="form-control"
              v-model="currentDetail.errors"
              name="errors_area"
              id="errors_area"
              cols="30"
              rows="10"
            >
            </textarea>
          </div>
          <div class="col-12 text-center p-2 justify-content-center">
            <b>{{ $t("order_form.measures_to_be_taken") }}</b>
            <textarea
              class="form-control"
              v-model="currentDetail.measures"
              name="measures_area"
              id="measures_area"
              cols="30"
              rows="15"
            >
            </textarea>
          </div>

          <!-- imza kısmı -->
          <div class="col-12 d-flex">
            <div
              class="flex-1 justify-content-center text-center pt-2 navy-blue-border-right-1px text-sm"
              style="height: 60px; font-size: 9px"
            >
              <b style="font-size: 9px">{{ $t("order_form.company_responsible") }}</b>
            </div>
            <div
              class="flex-1 justify-content-center text-center pt-2 navy-blue-border-right-1px text-sm"
              style="height: 60px; font-size: 9px"
            >
              <b style="font-size: 9px">{{ $t("order_form.workshop_manager") }}</b>
            </div>
            <div
              class="flex-1 justify-content-center text-center pt-2 text-sm"
              style="height: 60px"
            >
              <b style="font-size: 9px">{{ $t("order_form.manager") }}</b>
            </div>
          </div>
        </div>
      </template>
      <span
        v-if="isValidToSubmit && index == modelProxy.length - 1"
        class="cursor-pointer"
        @click="addNewForm"
        v-html="
          getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })
        "
      ></span>
      <span
        v-if="index > 0 && !currentDetail.id"
        class="cursor-pointer"
        @click="deleteNewForm(index)"
        v-html="
          getIconByKey('icons.sample.delete_red', {
            class: 'w-35px h-35px d-inline-block mr-3',
          })
        "
      ></span>

      <template
        v-if="
          isUserGranted(
            'OrderMidControlFinalReportForm',
            ['update', 'create', 'delete'],
            false,
            true
          )
        "
      >
        <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="order.id"
          name="mid-form"
          @onClickCancelForm="onClickCancelForm(currentDetail.id, index)"
          @submit="submitForm(index)"
        ></bottom-buttons>
      </template>
    </template>
  </div>
</template>
<script>
import TextInput from "@/assets/components/inputs/TextInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import BottomButtons from "@/view/pages/order/forms/layout/BottomButtons";
import OrderMidPdf from "@/view/pages/order/forms/pdf/OrderMidPdf";
import moment from "moment";
import { LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  UPDATE_ITEM_BY_ID,
  SET_LOADING,
  GET_ITEMS,
} from "@/core/services/store/REST.module";

import _ from "lodash";
import pdf from "vue-pdf";
import print from "vue-print-nb";
export default {
  name: "MidControl",
  components: {
    TextInput,
    TextareaInput,
    DatePickerInput,
    CustomMultiSelect,
    BottomButtons,
    OrderMidPdf,
  },
  directives: {
    print,
  },
  data() {
    return {
      is_submitting: false,
      orderReportData: [],
      reportPrintObject: {
        id: "reportPrint",
        popTitle: localStorage.getItem("VUE_APP_NAME"),
      },
    };
  },
  props: {
    model: {
      required: true,
    },
    defaultModel: {
      required: true,
    },
    supplierCompanies: {
      required: true,
    },
    orderSizeAndColors: {
      required: true,
    },
    bodySizes: {
      required: true,
    },
    formStopOptions: {
      required: true,
    },
    order: {
      required: true,
    },
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("update:modelProxy", value);
      },
    },
    bodySizeOptions() {
      let sizes = this.convertObjectToArray(this.orderSizeAndColors);
      return this.convertArrayToObjectByKey(sizes, "body_size_id", "name");
    },
    selectSizesFilter() {
      let items = [];
      let bodySizes = _.cloneDeep(this.bodySizes);
      Object.entries(bodySizes).forEach(([key, size]) => {
        if (this.form.measuring_location.includes(size.name)) {
          items.push(size.name);
        }
      });
      return items;
    },
  },
  methods: {
    deleteNewForm(index) {
      this.model.splice(index);
    },
    downloadPdf(pdf_link) {
      this.openInNewTab(pdf_link);
    },

    closeModal(name = "open_to_settings_table") {
      this.$refs[name].hide();
    },
    printPdf(selectReport, index) {
      this.orderReportData = [];
      let self = this;
      self.$store.commit(SET_LOADING, true);
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/orders/form/data",
          filters: {
            form: selectReport.id,
          },
        })
        .then((result) => {
          if (result.status) {
            this.orderReportData = result.data;
            self.$store.commit(SET_LOADING, false);
            self.$refs["display-mid-form-pdf"].show();
          }
        });
    },
    onClickCancelForm(itemId, index) {
      const self = this;
      this.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `api/order/${this.order.id}/form/${itemId}`,
        })
        .then(function (response) {
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
            self.modelProxy.splice(index, 1);
            if (!self.modelProxy.length) {
              self.modelProxy = [self.defaultModel];
            }
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    isValidToSubmit(index) {
      try {
        let returnData = true;
        const current = this.modelProxy[index];
        if (!current.date) returnData = false;
        if (current.errors === "" || current.errors === null) returnData = false;
        if (!current.form_type || current.form_type === "" || current.form_type === null)
          returnData = false;
        if (!current.measures || current.measures === "" || current.measures === null)
          returnData = false;
        if (!current.measuring_location.length) returnData = false;
        if (
          !current.operation_type ||
          current.operation_type === "" ||
          current.operation_type === null
        )
          returnData = false;
        if (!current.sizes.length) returnData = false;
        if (current.status == 0) {
          if (!current.stop_options.length) returnData = false;
        }
        if (!current.supplier_company_id) returnData = false;
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    submitForm(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    fixedData(index) {
      let items = [];
      let item = this.modelProxy[index];
      item.date = moment(item.date).format(LARAVEL_DATE_TIME_FORMAT);
      items.push(item);
      return items;
    },
    addNewForm() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.id = null;
      this.$emit("getIncomingFabricAmount");
      this.modelProxy.push(copy);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/order/${this.order.id}/form`,
          contents: {
            form: self.fixedData(index),
          },
        })
        .then((response) => {
          if (response.status) {
            let detail = self.modelProxy[index];
            let is_editing = !!detail.is_editing;
            let responseData = response.data[0];
            detail.is_editing = true;
            detail.id = responseData.id;

            self.modelProxy[index] = detail;

            self.sweetAlertSuccess(
              self.$t(
                is_editing
                  ? "general.successfully_updated"
                  : "general.successfully_created"
              )
            );
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
          self.sweetAlertClose();
          self.is_submitting = false;
        });
    },
    addNewSizeLine(index) {
      if (this.model[index].sizes.length <= 0) {
        this.sweetAlertError("Lütfen Beden Seçiniz");
        return false;
      }
      let tmpMeasuringLocation = {
        measuring_location: null,
        sizes: [],
      };
      this.model[index].sizes.forEach((size) => {
        if (this.bodySizeOptions[size]) {
          tmpMeasuringLocation.sizes.push({
            name: this.bodySizeOptions[size],
            size_id: +size,
            note_1: null,
            note_2: null,
            note_3: null,
          });
        }
      });
      this.model[index].measuring_location.push(tmpMeasuringLocation);
    },
    deleteMeasuringLocation(index, measuringIndex) {
      this.model[index].measuring_location.splice(measuringIndex, 1);
    },
    changeSizes(event, index) {
      let newValue = event.newValue;
      let oldValue = event.oldValue;

      let nonExistingItems = oldValue.filter((item) => !newValue.includes(item));

      nonExistingItems.forEach((item) => {
        let size = this.bodySizeOptions[item];
        this.model[index].measuring_location.forEach(
          (alreadyExitsControl, measuring_index) => {
            let findSize = _.findIndex(
              alreadyExitsControl.sizes,
              (e) => {
                return e.size_id == +item;
              },
              0
            );

            if (findSize != -1) {
              this.model[index].measuring_location[measuring_index].sizes.splice(
                findSize,
                1
              );
            }

            if (!newValue.length) {
              this.model[index].measuring_location = [];
            }
          }
        );
      });
      newValue.forEach((item) => {
        let size = this.bodySizeOptions[item];
        this.model[index].measuring_location.forEach(
          (alreadyExitsControl, measuring_index) => {
            if (alreadyExitsControl.sizes.find((e) => e.name === size)) {
            } else {
              if (this.bodySizeOptions[item]) {
                let tmpSize = {
                  name: this.bodySizeOptions[item],
                  size_id: +item,
                  note_1: null,
                  note_2: null,
                  note_3: null,
                };
                this.model[index].measuring_location[measuring_index].sizes.push(tmpSize);
              }
            }
          }
        );
      });
    },
    selectStatus(index) {
      if (this.model[index].status == 1) {
        this.model[index].stop_options = [];
      }
    },
  },
};
</script>
<style scoped>
.selected {
  opacity: 0.25;
  text-decoration: line-through;
}
.mid-control-master {
  border: 1px solid #0b5a85;
  background-color: #f3f6f9 !important;
  border-radius: 12px;
}
</style>
<style>
.navy-black-border-top-1px {
  border-top: 1px solid #ffb7c9d6 !important;
}
.order-delete-button{
  width: 23px; 
  height: 23px;
}
.order-plus-button{
  width: 23px; 
  height: 23px;
}

.mid-control-button-area{
  justify-content: space-between !important;
}
</style>
