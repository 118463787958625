<template>
  <tr>
    <th colspan="2">{{ lang["general.total"] }}</th>
    <template v-for="item in footer_array_sum">
      <th style="text-center">{{ item }}</th>
    </template>
    <th style="text-center">{{ numberFormat(item_sum.toFixed(2)) }}</th>
    <template v-for="sum_array in footer_fabric_sum_array">
      <th style="text-center">{{ numberFormat(sum_array.toFixed(2)) }}</th>
    </template>
    <th style="text-center">{{ numberFormat(footer_fabric_sum_array_total.toFixed(2) )}}</th>
  </tr>
</template>
<script>
export default {
  name: "SizesAmount",
  props: {
    lang: {
      required: true,
    },
    orderSizeAndColors: {
      required: true,
    },
    data: {
      required: true,
    },
    sizes: {
      required: true,
    },
    orderFabrics: {
      required: true,
    },
    dataSizeNamesHead: {
      required: true,
    },
  },
  computed: {
    footer_fabric_sum() {
      let data = [];
      this.data["order_fabrics"].forEach((order_fabric, index) => {
        data[index] = order_fabric["pastal"] * this.size_sum;
      });
      return data;
    },
    foter_fabric_sum(){
        let total=0;
        
    },
    size_sum() {
      let total = 0;
      this.sizes.forEach((size) => {
        if (this.dataSizeNamesHead.includes(size)) {
          this.data_size_amount.forEach((amount) => {
            total += +amount[size];
          });
        }
      });
      return total;
    },
    footer_fabric_sum_array_total(){
      let count=0;
        this.footer_fabric_sum.forEach((data_size_amount) => {
          count += +data_size_amount;
        });
      return count;
    },
    footer_fabric_sum_array() {
      let data = [];
      let count=0;
        this.footer_fabric_sum.forEach((data_size_amount) => {
          count = +data_size_amount;
          data.push(count);
        });
      return data;
    },
    footer_array_sum() {
      let data_size_amount_item = this.data_size_amount;
      let footer_array_sum = [];
      this.sizes.forEach((size) => {
        let col_sum = 0;
        data_size_amount_item.forEach((data_size) => {
          if (this.dataSizeNamesHead.includes(size)) {
            col_sum += data_size[size] ? +data_size[size] : 0;
          }
        });

        if (this.dataSizeNamesHead.includes(size)) {
          footer_array_sum.push(col_sum);
        }
      });
      return footer_array_sum;
    },
    item_sum() {
      let sum = 0;
      this.footer_array_sum.forEach((footer_sum) => {
        sum += +footer_sum;
      });
      return sum;
    },
    data_size_amount() {
      const totalData = [];
      this.data["order_size_and_colors"].forEach((data_size) => {
        let data = {};
        this.sizes.forEach((size) => {
          if (this.dataSizeNamesHead.includes(size)) {
            data[size] = 0;
          }
        });
        let body_sizes = JSON.parse(data_size["body_sizes"]);

        Object.entries(body_sizes).forEach((body_name) => {
          data[body_name[1]["name"]] = body_name[1]["amount"];
        });
        totalData.push(data);
      });
      return totalData;
    },
  },
  methods: {
    numberFormat(number) {
      return Number(number).toLocaleString("tr-TR", { minimumFractionDigits: 2 });
    },
  },
};
</script>
